<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="options.startDate"
              label="Start Date"
              readonly
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.startDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="3">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field
              dense
              v-model="options.endDate"
              label="End Date"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="options.endDate"
            @input="updateRange"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="Search items separate with a comma(,)"
          dense
          v-model="options.search"
        ></v-text-field>
      </v-col>
      <v-col cols="1">
        <v-btn small :loading="searchLoader" color="primary" @click="getItems"
          ><v-icon>mdi-card-search</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1">
        <v-btn small color="success" to="/discount-groups/items/create"
          >Add<v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          dense
          small
          :loading="loading"
          loading-text="Loading... Please wait"
          :headers="headers"
          :items="Items"
          :search="search"
          show-select
          :options.sync="options"
          :server-search="true"
        >
          <template v-slot:item.actions="props">
            <v-btn :to="`/discount-groups/items/${props.item.id}`" icon>
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn @click="removeItem(props.item.id)" color="white" plain small>
              <v-icon color="red">mdi-trash-can</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      search: null,
      searchLoader: false,
      loader: false,
      editedItem: {},
      selectedItems: [],
      Driver: null,
      drivers: [],
      item: {},
      vehicles: [],
      Vehicle: null,
      dialog: false,
      selectedData: null,
      groupByEnabled: false,
      updateRange: null,
      options: { startDate: null, endDate: null, DocStatus: "O", search: null },
      headers: [
        { text: "#", value: "id" },
        { text: "ValidFrom", value: "ValidFrom" },
        { text: "ValidTo", value: "ValidTo" },
        { text: "Type", value: "type" },
        { text: "Creator", value: "creator.name" },
        { text: "Actions", value: "actions" },
      ],

      Items: [],
    };
  },

  methods: {
    defaultDates() {
      if (!this.options.startDate) {
        const today = new Date();
        this.options.startDate = today.toISOString().substr(0, 10);
      }

      if (!this.options.endDate) {
        const today = new Date();
        this.options.endDate = today.toISOString().substr(0, 10);
      }
    },

    getItems() {
      this.selectedItems = [];
      const self = this;
      this.searchLoader = true;
     let url = `/discounts-group?ObjType=4`;
      if (this.options.startDate) {
        url += `${url.includes("?") ? "&" : "?"}StartDate=${
          this.options.startDate
        }`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}ValidFrom=${
          this.options.endDate
        }`;
      }
      if (this.options.endDate) {
        url += `${url.includes("?") ? "&" : "?"}ValidTo=${
          this.options.endDate
        }`;
      }
      if (this.options.search) {
        url += `${url.includes("?") ? "&" : "?"}search=${this.options.search}`;
      }

      this.loading = true;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          self.Items = res.ResponseData;
          this.searchLoader = false;
          this.loading = false;
          this.$store.commit("loader", false);
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
          this.searchLoader = false;
          this.loading = false;
        });
    },
    removeItem(id) {
      var userResponse = confirm("Are you sure you want to delete? This action cannot be undone.");
      if (userResponse === true) {
        const url = `/discounts-group/${id}`;
        const self = this;
        self.$store
          .dispatch("remove", url)
          .then(() => {
            this.getItems();
          })
          .catch((err) => {
            console.log(err, "err");

            this.$refs.snackbar.show(err, "red");
          });
      } else {
        alert("Operation Cancelled.");
      }
    },
  },
  created() {
    this.getItems();
  },
};
</script>

<style lang="scss" scoped>
</style>